import {request} from "../../../util/Request";
import Error from "../../../util/Error";

const DEFAULT_REEXPORT_STATE = {
    order_code: null,
    reexport_call_off_date_importer: null,
}

export default {
    data() {
        return{
            reexportUpdateModal: {
                visibility: false,
                formFields: {...DEFAULT_REEXPORT_STATE},
                formSubmitUrl: 'order/drafts/client/reexport/update',
            }
        }
    },
    methods: {
        handleReexportUpdateClick(record, url = "order/drafts/client/reexport/update") {
            this.reexportUpdateModal.visibility = true;
            this.reexportUpdateModal.formFields.order_code = record.order_code;
            this.reexportUpdateModal.formFields.reexport_call_off_date_importer = null;
            this.reexportUpdateModal.formSubmitUrl = url
        },
        async handleReexportUpdateSubmit() {
            try {
                const response = await request({
                    url: this.reexportUpdateModal.formSubmitUrl,
                    method: "POST",
                    data: {...this.reexportUpdateModal.formFields}
                })

                this.resetReexportUpdateOperation()
                const {success} = response
                if(success) {
                    this.itemUpdated()
                } else {
                    this.itemEditFails()
                }
                this.refreshList();
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        resetReexportUpdateOperation() {
            this.reexportUpdateModal.visibility = false;
            this.reexportUpdateModal.formFields = {...DEFAULT_REEXPORT_STATE};
        },
    }
}
