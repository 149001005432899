import {request} from "../../../util/Request";
import Error from "../../../util/Error";

const DEFAULT_DD_STATE = {
    order_code: null,
    dd_location: null,
    dd_call_off_date_importer: null,
}

export default {
    data() {
        return{
            ddUpdateModal: {
                visibility: false,
                formFields: {...DEFAULT_DD_STATE},
                formSubmitUrl: 'order/drafts/client/dd/update',
            }
        }
    },
    methods: {
        handleDdUpdateClick(record, url = 'order/drafts/client/dd/update') {
            this.ddUpdateModal.visibility = true;
            this.ddUpdateModal.formFields.order_code = record.order_code;
            this.ddUpdateModal.formFields.dd_location = null;
            this.ddUpdateModal.formFields.dd_call_off_date_importer = null;
            this.ddUpdateModal.formSubmitUrl = url
        },
        async handleDdUpdateSubmit() {
            try {
                const response = await request({
                    url: this.ddUpdateModal.formSubmitUrl,
                    method: "POST",
                    data: {...this.ddUpdateModal.formFields}
                })

                this.resetDdUpdateOperation()
                const {success} = response
                if(success) {
                    this.itemUpdated()
                } else {
                    this.itemEditFails()
                }
                this.refreshList();
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        resetDdUpdateOperation() {
            this.ddUpdateModal.visibility = false;
            this.ddUpdateModal.formFields = {...DEFAULT_DD_STATE};
        },
    }
}
