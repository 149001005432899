import {request} from "../../../util/Request";
import Error from "../../../util/Error";

const DEFAULT_TRANSFER_MODAL_STATE = {
    id: null,
    identification_number: null,
}

export default {
    data() {
        return{
            transferClientModal: {
                visibility: false,
                formFields: {...DEFAULT_TRANSFER_MODAL_STATE},
            }
        }
    },
    methods: {
        handleTransferClientClick(record) {
            this.transferClientModal.visibility = true;
            this.transferClientModal.formFields.id = record.id;
            this.transferClientModal.formFields.identification_number = null;
        },
        async handleTransferClientSubmit() {
            try {
                const response = await request({
                    url: "order/drafts/client/transfer/client",
                    method: "POST",
                    data: {...this.transferClientModal.formFields}
                })

                this.resetTransferClientOperation()
                const {success} = response
                if(success) {
                    this.itemUpdated()
                } else {
                    this.itemEditFails()
                }
                this.refreshList();
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        resetTransferClientOperation() {
            this.transferClientModal.visibility = false;
            this.transferClientModal.formFields = {...DEFAULT_TRANSFER_MODAL_STATE};
        },
    }
}
