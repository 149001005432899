<template>
    <div v-if="detailLoad">
        <b-row>
            <b-col offset-md="2" md="8" offset-lg="2" lg="8" sm="12">
                <div class="card p-4">
                    <div>
                        <b-row>
                            <b-col lg="10" md="10" sm="12">
                                <h4>
                                    <span class="text-capitalize">{{$t('title.orderCode')}}#{{detailLoad.order_code}} </span>
                                    <span><small><od-type :type="detailLoad.order_type"></od-type></small></span>
                                </h4>
                                <h6>
                                    <span class="text-secondary">
                                        {{$global.utcDateToLocalDate(detailLoad.created_at) }}
                                    </span>
                                </h6>
                            </b-col>
                            <b-col lg="2" md="2" sm="12" class="text-right">
                                <b-button variant="warning" class="ml-3"
                                          size="sm" @click="handleCancelClick"
                                          v-b-tooltip.hover :title="$t('button.title.back')">
                                    <i class="fa fa-arrow-left mr-1"></i> {{$t('button.back')}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mt-3">
                        <div class="box border rounded p-4">
                            <div class="clearfix">
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.brandName')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.brand_name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.factoryReferenceNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.factory_reference_number}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.orderCountry')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.order_country}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.mpoNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.mpo_number}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.modelCode')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.model_code}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.versionType')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.version_type}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.category')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.category}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.fuelType')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.fuel_type}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.vinNumber')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.vin_number}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.invoiceNumberImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.invoice_number_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.destinationCountryImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.destination_country_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.deliveryPlaceImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.delivery_place_importer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.compoundToCompoundLocation')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.second_delivery_place_importer}}</div>
                                        <div class="text-right" v-if="detailLoad.cc_supplier_compound_location">
                                            {{detailLoad.cc_supplier_compound_location.label}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.dealerId')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.dealer_id}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.dealerName')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.dealer_name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.dealerZone')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.dealer_zone}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.deliveryPlaceDealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.delivery_place_dealer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.destinationCountryDealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.destination_country_dealer}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.modelDescription')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.model_description}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.expectedProductionDate')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.expected_production_date ? $global.dateFormat(detailLoad.expected_production_date) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.actualProductionDate')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.actual_production_date ? $global.dateFormat(detailLoad.actual_production_date) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.manufacturerInvoiceDate')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.manufacturer_invoice_date ? $global.dateFormat(detailLoad.manufacturer_invoice_date) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.invoiceDateImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.invoice_date_importer ? $global.dateFormat(detailLoad.invoice_date_importer) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.callOffDateImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.call_off_date_importer ? $global.dateFormat(detailLoad.call_off_date_importer) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.lastUpdatedAt')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.updated_at ? $global.utcDateToLocalDate(detailLoad.updated_at) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.ddCallOffDateImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.dd_call_off_date_importer ? $global.dateFormat(detailLoad.dd_call_off_date_importer) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.reexportCallOffDateImporter')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.reexport_call_off_date_importer ? $global.dateFormat(detailLoad.reexport_call_off_date_importer) : '')}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">DD {{$t('title.dealer')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{(detailLoad.dealer_for_dealer_to_dealer || {}).name}}</div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                                <hr>
                                <b-row>
                                    <b-col md="6" lg="6" sm="12">
                                        <h6><span class="text-capitalize">{{$t('title.ddLocationCode')}}</span></h6>
                                    </b-col><!--/.col-->
                                    <b-col md="6" lg="6" sm="12">
                                        <div class="text-right">{{detailLoad.dd_location}}</div>
                                        <div class="text-right" v-if="detailLoad.dealer_to_dealer_location">
                                            {{detailLoad.dealer_to_dealer_location.label}}
                                        </div>
                                    </b-col><!--/.col-->
                                </b-row><!--/.row-->
                            </div><!--/.clearfix-->
                        </div><!--/.box-->
                    </div>
                </div><!--/.card-->
            </b-col><!--/.col-->
        </b-row><!--/.row-->
    </div><!--/div-->
</template>
<script>
    import {request} from "../../../util/Request";

    export default {
        props: ['id', 'handleCloseOperation'],
        data() {
            return {
                detailLoad: null,
                items: []
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.operation === 'detail' && this.$route.query.oToken) {
                this.getDetail(this.$route.query.oToken)
            } else {
                this.serverError()
            }
        },
        methods: {
            async getDetail(id) {
                await request({
                    url: `/order/drafts/client/detail/${id}`,
                })
                    .then((response) => {
                        const {data} = response
                        this.detailLoad = data
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            },
            handleCancelClick() {
                this.handleCloseOperation()
            }
        }
    }
</script>
