import {request} from "../../../util/Request";
import Error from "../../../util/Error";

const DEFAULT_CALL_STATE = {
    order_code: null,
    call_off_date_importer: null,
}

export default {
    data() {
        return{
            callOffDateUpdateModal: {
                visibility: false,
                formFields: {...DEFAULT_CALL_STATE},
                formSubmitUrl: 'order/drafts/client/call/of/date/update',
            }
        }
    },
    methods: {
        handleCallOffDateUpdateClick(record, url = 'order/drafts/client/call/of/date/update') {
            this.callOffDateUpdateModal.visibility = true;
            this.callOffDateUpdateModal.formFields.order_code = record.order_code;
            this.callOffDateUpdateModal.formFields.call_off_date_importer = null;
            this.callOffDateUpdateModal.formSubmitUrl = url;
        },
        async handleCallOffDateUpdateSubmit() {
            try {
                const response = await request({
                    url: this.callOffDateUpdateModal.formSubmitUrl,
                    method: "POST",
                    data: {...this.callOffDateUpdateModal.formFields}
                })

                this.resetCallOffDateUpdateOperation()
                const {success} = response
                if(success) {
                    this.itemUpdated()
                } else {
                    this.itemEditFails()
                }
                this.refreshList();
            } catch (error) {
                if (error.request && error.request.status && error.request.status === 422) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                    return false
                }

                this.handleServerError(error)
            }
        },
        resetCallOffDateUpdateOperation() {
            this.callOffDateUpdateModal.visibility = false;
            this.callOffDateUpdateModal.formFields = {...DEFAULT_CALL_STATE};
        },
    }
}
